@import '../sass/variaveis';
@import '../sass/utilities/reset';

@import '../sass/mixins'; //MIXINS
@import '../sass/utilities/animation';

//components
@import '../sass/components/body'; //BODY
@import '../sass/components/menu/menu';
@import '../sass/components/footer';
@import '../sass/components/abstract.scss';
@import '../sass/components/loader_basic.scss';
@import '../sass/components/login.scss';

@import '../sass/pages/page_home';

// PÁGINAS
main {
	overflow: hidden;
}
@include modal_basic();


.pswp {
	--pswp-root-z-index: 1000000000000000;
}

.pointer-events-none {
	pointer-events: none;
}
